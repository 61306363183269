<template>
    <div class="menu">
        <navigation></navigation>
        <div id=container>
            <div class="slider-wrap showcase om-section block-0" id=slider-9ae29c08a50641602b510d16b5084bcc>
                <ul class="slider arrows arrows-hidden dots dots-default" data-slider-speed=5000 data-height=fixed-ratio data-att=35>
                    <li class>
                        <figure>
                            <img alt="Brasserie Den Anker" src="../assets/img/DenAnker6-min.jpg">
                            <figcaption>Brasserie Den Anker</figcaption>
                        </figure>
                    </li>
                </ul>
                <div class="overlay-wrap fixed-center">
                    <section class=overlay>
                        <h1>Menu</h1>
                        <!--                        <h2>Momenten</h2>-->
                        <!--                        <h3>Om nooit te vergeten</h3>-->
                        <p>
                            <router-link class=button to="/reserveren">Reserveren</router-link>
                        </p>
                    </section>
                </div>
            </div>

            <div class="content-wrap content-form om-section block-1"><a class="anchor scrollto-anchor" id=scrollto-anchor-1></a>
                <section class=content>
                    <h1 id="menu-title" style="margin-bottom: 30px" class="entry-title"><span>Menukaart</span></h1>
                </section>
            <facebook-loader v-if="loading"></facebook-loader>
            </div>


            <form v-if="!loading" id="app-cover">
                <div id="select-box">
                    <input type="checkbox" id="options-view-button">
                    <div id="select-button" class="brd">
                        <div id="selected-value">
                            <span style="font-weight:700 !important">Bekijk categorieën</span>
                        </div>
                        <div id="chevrons">
                            <i class="fas fa-chevron-down"></i>
                        </div>
                    </div>
                    <div id="options">
                        <div v-for="(menu, index) in items">
                            <div @click.prevent="menuSelect(menu.category)" class="option">
                                <span style="border-bottom: 1px solid rgba(255, 255, 255, .15);;color:#fff" class="label">{{ menu.category }}</span>
                            </div>
                        </div>
                        <div id="option-bg"></div>
                    </div>
                </div>
            </form>

            <section v-if="!loading" class="Page-header Page-section Page-section--grayLightest mb-5">
                <div class="Container">
                    <div class="Row u-paddingVertical--half js-affix-subnavigation-offset">
                        <aside class="Col--medium--4">
                            <nav class="Navigation Navigation--sub js-affix-subnavigation js-subnavigation">
                                <div class="Navigation-toggable">
                                    <div v-for="(menu, index) in items" class="DishType"><a @click.prevent="menuSelect(menu.category)"><i class="fas fa-angle-double-right Icon--prepend"></i>{{ menu.category }}</a></div>
                                </div>
                                <a class="Navigation-toggle js-subnavigation-toggle Button Button--dark">
                                    Bekijk Categorieën
                                    <i class="Icon Icon--append Icon--default fa fa-chevron-down"></i><i class="Icon Icon--append Icon--active fa fa-times"></i></a></nav>
                        </aside>
                        <div class="Col--medium--8 u-paddingVertical--half">
                            <div id="DishCategory" class="DishCategory">
                                <h2 v-if="activeMenu" class="DishCategory-title">{{ activeMenu }}</h2>
                                <ul class="Dish-list List--unstyled">
                                    <li v-for="(item, index) in activeItems" class="Dish">
                                        <div class="Row">
                                            <div class="Dish-inner">
                                                <div class="Col--small--10 Dish-content">
                                                    <h3 class="Dish-title">{{ item.item }}</h3>
                                                    <small style="font-size: 12px">{{ item.description }}</small>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <app-footer></app-footer>
        <svg id=icon-sprite-social>
            <defs>
                <symbol id=icon-facebook>
                    <path d="M3.7 10h2.1V5h1.4l.2-1.7H5.8v-.9c0-.4 0-.7.7-.7h.9V0H5.9C4.3 0 3.7.8 3.7 2.2v1h-1V5h1v5z"/>
                </symbol>
            </defs>
        </svg>
    </div>
</template>
<script>
    import Navigation from '../components/Navigation'
    import AppFooter                        from '../components/Footer'
    import {FacebookLoader} from 'vue-content-loader'

    export default {
        name: 'Menu',
        components: {
            AppFooter,
            Navigation,
            FacebookLoader,
        },
        data() {
            return {
                activeItems: [],
                activeMenu: 'KIES EEN CATEGORIE',
                menus: [],
                items: [],
                loading: true
            }
        },
        mounted() {
            $(window).scrollTop(0)
            this.$axios.get('api/getMenusWebsite').then(response => {
                this.menus = response.data
                this.getCategories()
                // this.menuSelect(this.activeMenu)
            }).catch(error => {
                console.log(error)
            })
        },
        methods: {
            menuSelect(menu) {
                var width = window.innerWidth
                if (width > 1000) {
                    var elmnt = document.getElementById('menu-title')
                    // elmnt.scrollIntoView()
                }
                this.activeMenu  = menu
                this.activeItems = this.menus.filter(item => item.category === menu)
                var el           = document.getElementById('options-view-button')
                el.checked       = false
            },
            getCategories() {
                this.$axios.get('api/getCategories').then(response => {
                    this.items = response.data
                    this.loading = false
                }).catch(error => {
                    console.log(error)
                })
            },
        }
    }
</script>
<style scoped>
    .text-with-dots {
        display: block;
        max-width: 98%;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
    }

    .slider-wrap figure {
        min-height: 65vh;
    }

    #container .slider-wrap figure img {
        height: 65vh !important;
    }

    .js .animate-box {
        opacity: 0;
    }

    * {
        box-sizing: border-box;
    }

    .col-md-8, .col-md-12 {
        position: relative;
        min-height: 1px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .animated {
        animation-duration: 1s;
        animation-fill-mode: both;
    }

    .fadeInUp {
        animation-name: fadeInUp;
    }

    .row {
        margin-left: -15px;
        margin-right: -15px;
    }

    .row::before, .row::after {
        content: " ";
        display: table;
    }

    .row::after {
        clear: both;
    }

    .text-center {
        text-align: center;
    }

    ul, ol {
        display: block;
        margin-bottom: 1.5em;
    }

    .nav-tabs {
        border: none;
        display: inline-block;
        transition: all 0.3s ease 0s;
    }

    ul, ol {
        margin-top: 0px;
        margin-bottom: 10px;
    }

    .nav {
        margin-bottom: 0px;
        padding-left: 0px;
        list-style: none;
    }

    .nav::before, .nav::after {
        content: " ";
        display: table;
    }

    .nav::after {
        clear: both;
    }

    .nav-tabs {
        border-bottom: 1px solid rgb(221, 221, 221);
    }

    .nav-tabs li {
        margin: 0px auto;
        font-size: 20px;
        display: block;
        font-weight: 500;
    }

    .nav > li {
        position: relative;
        display: block;
    }

    .nav-tabs > li {
        float: left;
        margin-bottom: -1px;
    }

    a {
        text-decoration: none;
        transition: all 0.5s ease 0s;
    }

    a:hover {
        text-decoration: underline;
    }

    a:focus, a:active {
        outline: none;
    }

    .nav-tabs li a {
        background: transparent;
        color: rgb(64, 64, 68);
        padding: 0px;
        margin: 0px 1em;
        border: none !important;
    }

    .nav-tabs li a:hover, .nav-tabs li a:focus {
        background: transparent;
    }

    .nav > li > a {
        position: relative;
        display: block;
        padding: 10px 15px;
    }

    .nav > li > a:hover, .nav > li > a:focus {
        text-decoration: none;
        background-color: rgb(238, 238, 238);
    }

    .nav-tabs > li > a {
        margin-right: 2px;
        line-height: 1.42857;
        border: 1px solid transparent;
        border-radius: 4px 4px 0px 0px;
    }

    .nav-tabs > li > a:hover {
        border-color: rgb(238, 238, 238) rgb(238, 238, 238) rgb(221, 221, 221);
    }

    .nav-tabs li.active a {
        position: relative;
        border: none !important;
        color: rgb(64, 64, 68) !important;
        background: transparent !important;
    }

    .nav-tabs li.active a::after {
        position: absolute;
        bottom: -5px;
        left: 0px;
        right: 0px;
        content: "";
        width: 100%;
        height: 2px;
        background: rgb(216, 153, 90);
        margin: 0px auto;
    }

    .nav-tabs > li.active > a, .nav-tabs > li.active > a:hover, .nav-tabs > li.active > a:focus {
        color: rgb(85, 85, 85);
        background-color: rgb(255, 255, 255);
        border-width: 1px;
        border-style: solid;
        border-color: rgb(221, 221, 221) rgb(221, 221, 221) transparent;
        border-image: initial;
        cursor: default;
    }

    .tab-pane {
        padding: 2em 0px;
        transition: all 0.3s ease 0s;
    }

    .tab-content > .tab-pane {
        display: none;
    }

    .menu-dish {
        padding: 0px 2em;
        margin: 0px;
    }

    .menu-dish li {
        margin: 0px;
        list-style: none;
        display: block;
        position: relative;
        padding: 10px;
        transition: all 0.3s ease 0s;
    }

    .menu-dish li:hover, .menu-dish li:focus, .menu-dish li.active {
        background: rgba(255, 255, 255, 0.1);
    }

    .menu-dish li .dish-entry, .menu-dish li .text {
        display: table-cell;
        vertical-align: top;
    }

    .menu-dish li .text {
        padding-left: 20px;
    }

    p, span {
        /*margin-bottom: 1.5em;*/
        font-weight: 400;
    }

    .menu-dish li .text .price {
        position: absolute;
        top: 15px;
        right: 10px;
        font-size: 20px;
        color: rgb(64, 64, 68);
    }

    .menu-dish li .text h3 {
        margin: 10px 0px;
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
    }

    .menu-dish li .text .cat {
        color: rgb(125, 125, 125);
        font-size: 14px;
    }

    p {
        margin: 0px 0px 10px;
    }

    .tab-content > .active {
        display: block;
    }

    @media (min-width: 992px) {
        .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
            float: left;
        }
    }

    @media (min-width: 992px) {
        .col-md-12 {
            width: 100%;
        }
    }

    @media (min-width: 992px) {
        .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
            float: left;
        }
    }

    @media (min-width: 992px) {
        .col-md-12 {
            width: 100%;
        }
    }

    @media screen and (max-width: 768px) {
        .nav-tabs li {
            font-size: 12px;
        }
    }

    @media screen and (max-width: 768px) {
        .nav-tabs li {
            font-size: 12px;
        }
    }

    @media screen and (max-width: 480px) {
        .menu-dish li .text .price {
            right: 0px;
            top: 0px;
            position: relative;
            display: block;
            margin-bottom: 0px;
        }
    }

    @media screen and (max-width: 768px) {
        .menu-dish li .text h3 {
            margin-top: 0px;
        }
    }

    @media screen and (max-width: 480px) {
        .menu-dish li .text .price {
            right: 0px;
            top: 0px;
            position: relative;
            display: block;
            margin-bottom: 0px;
        }
    }

    @media screen and (max-width: 768px) {
        .menu-dish li .text h3 {
            margin-top: 0px;
        }
    }

    @media screen and (max-width: 480px) {
        .menu-dish li .text .price {
            right: 0px;
            top: 0px;
            position: relative;
            display: block;
            margin-bottom: 0px;
        }
    }

    @media screen and (max-width: 768px) {
        .menu-dish li .text h3 {
            margin-top: 0px;
        }
    }

    @media screen and (max-width: 480px) {
        .menu-dish li .text .price {
            right: 0px;
            top: 0px;
            position: relative;
            display: block;
            margin-bottom: 0px;
        }
    }

    @media screen and (max-width: 768px) {
        .menu-dish li .text h3 {
            margin-top: 0px;
        }
    }

    @media screen and (max-width: 480px) {
        .menu-dish li .text .price {
            right: 0px;
            top: 0px;
            position: relative;
            display: block;
            margin-bottom: 0px;
        }
    }

    @media print {
        *, ::before, ::after {
            background: transparent !important;
            color: rgb(0, 0, 0) !important;
            box-shadow: none !important;
            text-shadow: none !important;
        }
    }

    @media screen and (max-width: 768px) {
        .menu-dish li .text h3 {
            margin-top: 0px;
        }
    }

    @media screen and (max-width: 480px) {
        .menu-dish li .text .price {
            right: 0px;
            top: 0px;
            position: relative;
            display: block;
            margin-bottom: 0px;
        }
    }

    @media screen and (max-width: 768px) {
        .menu-dish li .text h3 {
            margin-top: 0px;
        }
    }

    @media screen and (max-width: 480px) {
        .menu-dish li .text .price {
            right: 0px;
            top: 0px;
            position: relative;
            display: block;
            margin-bottom: 0px;
        }
    }

    @media screen and (max-width: 768px) {
        .menu-dish li .text h3 {
            margin-top: 0px;
        }
    }

    @media screen and (max-width: 480px) {
        .menu-dish li .text .price {
            right: 0px;
            top: 0px;
            position: relative;
            display: block;
            margin-bottom: 0px;
        }
    }

    @media print {
        *, ::before, ::after {
            background: transparent !important;
            color: rgb(0, 0, 0) !important;
            box-shadow: none !important;
            text-shadow: none !important;
        }
    }

    @media screen and (max-width: 768px) {
        .menu-dish li .text h3 {
            margin-top: 0px;
        }
    }

    @media screen and (max-width: 480px) {
        .menu-dish li .text .price {
            right: 0px;
            top: 0px;
            position: relative;
            display: block;
            margin-bottom: 0px;
        }
    }

    @media screen and (max-width: 768px) {
        .menu-dish li .text h3 {
            margin-top: 0px;
        }
    }

    @media screen and (max-width: 480px) {
        .menu-dish li .text .price {
            right: 0px;
            top: 0px;
            position: relative;
            display: block;
            margin-bottom: 0px;
        }
    }

    @media screen and (max-width: 768px) {
        .menu-dish li .text h3 {
            margin-top: 0px;
        }
    }

    @media screen and (max-width: 480px) {
        .menu-dish li .text .price {
            right: 0px;
            top: 0px;
            position: relative;
            display: block;
            margin-bottom: 0px;
        }
    }

    @media screen and (max-width: 768px) {
        .menu-dish li .text h3 {
            margin-top: 0px;
        }
    }

    @media screen and (max-width: 480px) {
        .menu-dish li .text .price {
            right: 0px;
            top: 0px;
            position: relative;
            display: block;
            margin-bottom: 0px;
        }
    }

    @media screen and (max-width: 768px) {
        .menu-dish li .text h3 {
            margin-top: 0px;
        }
    }

    @media screen and (max-width: 480px) {
        .menu-dish li .text .price {
            right: 0px;
            top: 0px;
            position: relative;
            display: block;
            margin-bottom: 0px;
        }
    }

    @media screen and (max-width: 768px) {
        .menu-dish li .text h3 {
            margin-top: 0px;
        }
    }

    @media screen and (max-width: 480px) {
        .menu-dish li .text .price {
            right: 0px;
            top: 0px;
            position: relative;
            display: block;
            margin-bottom: 0px;
        }
    }

    @media screen and (max-width: 768px) {
        .menu-dish li .text h3 {
            margin-top: 0px;
        }
    }

    @media screen and (max-width: 480px) {
        .menu-dish li .text .price {
            right: 0px;
            top: 0px;
            position: relative;
            display: block;
            margin-bottom: 0px;
        }
    }

    @media screen and (max-width: 768px) {
        .menu-dish li .text h3 {
            margin-top: 0px;
        }
    }

    article, aside, details, figcaption, figure, footer, header, hcategory, main, nav, section, summary {
        display: block;
    }

    *, ::after, ::before {
        box-sizing: inherit;
    }

    .Page-header {
        padding-bottom: 11px;
        margin: 48px 0px 24px;
        border-bottom: 1px solid rgb(230, 229, 229);
    }

    .Page-header {
        padding-bottom: 0px;
        margin: 0px;
        border-bottom: 0px;
    }

    .Page-header {
        position: relative;
        margin: 0px;
        padding-bottom: 0px;
        border-bottom: 0px;
    }

    .Page-section.Page-section--grayLightest {
        color: #a09174;
    }

    .Page-section.Page-section--grayLightest {
        color: #a09174;
    }

    .Container {
        margin-bottom: 40px !important;
        margin-right: auto;
        margin-left: auto;
        max-width: calc(1280px + 3rem);
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }

    .Container::after, .Container::before {
        content: " ";
        display: table;
    }

    .Container::after {
        clear: both;
    }

    .App-footer-section > :first-child, .Dropdown--generical .Dropdown-menu > :first-child, .Page-section > :first-child, .Tab-content > .Tab-pane > :first-child, .u-clearInnerMargin > :first-child {
        margin-top: 0px;
    }

    .App-footer-section > :last-child, .Dropdown--generical .Dropdown-menu > :last-child, .Page-section > :last-child, .Tab-content > .Tab-pane > :last-child, .u-clearInnerMargin > :last-child {
        margin-bottom: 0px;
    }

    .Row {
        margin-left: -0.75rem;
        margin-right: -0.75rem;
    }

    .Row::after, .Row::before {
        content: " ";
        display: table;
    }

    .Row::after {
        clear: both;
    }

    .u-paddingVertical--half {
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
    }

    .Col--medium--4, .Col--medium--8, .Col--small--10 {
        position: relative;
        min-height: 1px;
        padding-left: 0.75rem;
        padding-right: 0.75rem;
    }

    .Navigation--sub {
        position: relative;
    }

    .Navigation-toggable {
        background: rgb(240, 235, 232);
        position: absolute;
        padding: 1.5rem;
        top: 3em;
        left: 0px;
        right: 0px;
        z-index: 1;
        display: none;
    }

    a {
        background: 0px 0px;
    }

    a:active, a:hover {
        outline: 0px;
    }

    .Page-section.Page-section--grayLightest a:not(.Button) {
        color: #a09174;
    }

    .Page-section.Page-section--grayLightest a:focus:not(.Button), .Page-section.Page-section--grayLightest a:hover:not(.Button) {
        color: rgb(161, 124, 92);
        cursor: pointer;
    }

    .fa {
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        font-weight: normal;
        font-stretch: normal;
        line-height: 1;
        font-family: FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
    }

    .fa-angle-right::before {
        content: "";
    }

    .Icon--prepend {
        margin-right: 0.75rem;
    }

    ol, ul {
        margin-top: 0px;
        margin-bottom: 12px;
    }

    .List--unstyled {
        padding-left: 0px;
        list-style: none;
    }

    ol, ul {
        margin-top: 0px;
        margin-bottom: 0.75rem;
    }

    .Navigation--sub ul > li {
        margin-left: 1.5rem;
    }

    .fa-angle-double-right::before {
        content: "";
    }

    nav a {
        text-align: left;
    }

    .Button {
        display: inline-block;
        margin-bottom: 0px;
        font-weight: inherit;
        text-align: center;
        vertical-align: middle;
        touch-action: manipulation;
        cursor: pointer;
        background-image: none;
        border: 1px solid transparent;
        white-space: nowrap;
        text-decoration: none;
        padding: 6px 12px;
        font-size: 16px;
        line-height: 1.5;
        border-radius: 0.375em;
        user-select: none;
    }

    .Button.focus, .Button.is-active.focus, .Button.is-active:focus, .Button:active.focus, .Button:active:focus, .Button:focus {
        outline: -webkit-focus-ring-color auto 5px;
        outline-offset: -2px;
    }

    .Button.focus, .Button:focus, .Button:hover {
        color: rgb(51, 51, 51);
        text-decoration: none;
    }

    .Button.is-active, .Button:active {
        outline: 0px;
        background-image: none;
        box-shadow: rgba(0, 0, 0, 0.125) 0px 3px 5px inset;
    }

    .Button {
        border: 0px solid transparent;
        text-transform: uppercase;
        padding: 0.375em 1.5em;
        font-size: 1em;
        line-height: 1.5;
        border-radius: 0.25em;
    }

    .Button.is-active, .Button:active {
        outline: 0px;
        background-image: none;
    }

    .Button--dark {
        color: rgb(255, 255, 255);
        background-color: rgb(128, 134, 140);
        border-color: rgb(125, 131, 138);
    }

    .Button--dark.focus, .Button--dark.is-active, .Button--dark:active, .Button--dark:focus, .Button--dark:hover, .is-open .Dropdown-toggle .Button--dark {
        background-color: rgb(108, 114, 119);
        color: rgb(235, 235, 235);
        border-color: rgb(106, 111, 117);
    }

    .Button--dark.is-active, .Button--dark:active, .is-open .Dropdown-toggle .Button--dark {
        background-image: none;
        box-shadow: none;
        transform: translate(0px, 3px);
    }

    .Navigation-toggle {
        z-index: 2;
        position: relative;
        display: block;
        margin-bottom: 0.75rem;
    }

    .fa-chevron-down::before {
        content: "";
    }

    a .Icon {
        text-decoration: none;
    }

    .Icon--append {
        margin-left: 0.75rem;
    }

    .Navigation-toggle .Icon--default {
        display: inline-block;
    }

    .fa-close::before, .fa-remove::before, .fa-times::before {
        content: "";
    }

    .Navigation-toggle .Icon--active {
        display: none;
    }

    .DishCategory {
        padding-top: 3rem;
    }

    .DishCategory:first-child {
        padding-top: 0px;
    }

    h2, h3, h4, h5, h6 {
        font-family: inherit;
        font-weight: 700;
        line-height: 1.1;
        color: inherit;
    }

    .Page-section.Page-section--grayLightest h2 {
        color: #fff;
    }

    .DishCategory-title {
        margin: 0px;
        padding: 0.75rem;
        background: #a09174;
        color: #fff;
        text-transform: uppercase;
        letter-spacing: 4px;
        font-size: 1rem;
    }

    .Dish {
        margin-top: 0.75rem;
        border-bottom: 1px dashed rgb(170, 170, 170);
        padding-bottom: 0.375rem;
    }

    .Dish-inner {
        display: table;
        width: 100%;
    }

    .Dish-content {
        padding: 0px 1.5rem;
    }

    .Dish-content, .Dish-price {
        display: table-cell;
    }

    .Page-section.Page-section--grayLightest h3, .Page-section.Page-section--grayLightest h4 {
        color: #444;
    }

    .Dish-title {
        font-size: 1rem;
        margin: 0px;
        line-height: 1.5;
        text-transform: uppercase;
    }

    @media (min-width: 1000px) {
        .Col--medium--4, .Col--medium--8 {
            float: left;
        }
    }

    @media (min-width: 1000px) {
        .Col--medium--4 {
            width: 33.3333%;
        }
    }

    @media (min-width: 1000px) {
        .Navigation--sub {
            font-size: 1rem;
        }
    }

    @media (min-width: 1000px) {
        .Navigation-toggable {
            display: block;
            position: static;
            background-color: transparent;
            padding: 0px;
        }

        #app-cover {
            display: none
        }
    }

    @media (max-width: 1000px) {
        .option:hover {
            background: #fff !important
        }
    }

    @media (min-width: 1000px) {
        .Navigation-toggle {
            display: none;
        }
    }

    @media (min-width: 1000px) {
        .Col--medium--8 {
            width: 66.6667%;
        }
    }

    @media (min-width: 750px) {
        .u-h2, h2 {
            font-size: 2.25em;
        }
    }


    @media (min-width: 750px) {
        .Col--small--10 {
            width: 83.3333%;
        }
    }

    @media (min-width: 750px) {
        .Box-title, .u-h3, h3 {
            font-size: 1.83712em;
        }
    }


    @media (min-width: 750px) {
        .Col--small--10 {
            width: 83.3333%;
        }
    }

    @media (min-width: 750px) {
        .Box-title, .u-h3, h3 {
            font-size: 1.83712em;
        }
    }


    @media (min-width: 750px) {
        .Col--small--10 {
            width: 83.3333%;
        }
    }

    @media (min-width: 750px) {
        .Box-title, .u-h3, h3 {
            font-size: 1.83712em;
        }
    }


    @media (min-width: 750px) {
        .Col--small--10 {
            width: 83.3333%;
        }
    }

    @media (min-width: 750px) {
        .Box-title, .u-h3, h3 {
            font-size: 1.83712em;
        }
    }


    @media (min-width: 750px) {
        .Col--small--10 {
            width: 83.3333%;
        }
    }

    @media (min-width: 750px) {
        .Box-title, .u-h3, h3 {
            font-size: 1.83712em;
        }
    }

    @media (min-width: 750px) {
        .u-h2, h2 {
            font-size: 2.25em;
        }
    }


    @media (min-width: 750px) {
        .Col--small--10 {
            width: 83.3333%;
        }
    }

    @media (min-width: 750px) {
        .Box-title, .u-h3, h3 {
            font-size: 1.83712em;
        }
    }


    @media (min-width: 750px) {
        .Col--small--10 {
            width: 83.3333%;
        }
    }

    @media (min-width: 750px) {
        .Box-title, .u-h3, h3 {
            font-size: 1.83712em;
        }
    }


    @media (min-width: 750px) {
        .Col--small--10 {
            width: 83.3333%;
        }
    }

    @media (min-width: 750px) {
        .Box-title, .u-h3, h3 {
            font-size: 1.83712em;
        }
    }

    @media (min-width: 750px) {
        .Col--small--1, .Col--small--10, .Col--small--11, .Col--small--12, .Col--small--2, .Col--small--3, .Col--small--4, .Col--small--5, .Col--small--6, .Col--small--7, .Col--small--8, .Col--small--9 {
            float: left;
        }
    }

    @media (min-width: 750px) {
        .Col--small--10 {
            width: 83.3333%;
        }
    }

    @media (min-width: 750px) {
        .Box-title, .u-h3, h3 {
            font-size: 1.83712em;
        }
    }

    @media (min-width: 750px) {
        .u-h2, h2 {
            font-size: 2.25em;
        }
    }


    @media (min-width: 750px) {
        .Col--small--10 {
            width: 83.3333%;
        }
    }

    @media (min-width: 750px) {
        .Box-title, .u-h3, h3 {
            font-size: 1.83712em;
        }
    }

    @media (min-width: 750px) {
        .Col--small--10 {
            width: 83.3333%;
        }
    }

    @media (min-width: 750px) {
        .Box-title, .u-h3, h3 {
            font-size: 1.83712em;
        }
    }

    @media (min-width: 750px) {
        .u-h2, h2 {
            font-size: 2.25em;
        }
    }


    @media (min-width: 750px) {
        .Col--small--10 {
            width: 83.3333%;
        }
    }

    @media (min-width: 750px) {
        .Box-title, .u-h3, h3 {
            font-size: 1.83712em;
        }
    }


    @media (min-width: 750px) {
        .Col--small--10 {
            width: 83.3333%;
        }
    }

    @media (min-width: 750px) {
        .Box-title, .u-h3, h3 {
            font-size: 1.83712em;
        }
    }


    @media (min-width: 750px) {
        .Col--small--10 {
            width: 83.3333%;
        }
    }

    @media (min-width: 750px) {
        .Box-title, .u-h3, h3 {
            font-size: 1.83712em;
        }
    }

    @media (min-width: 750px) {
        .Col--small--10 {
            width: 83.3333%;
        }
    }

    @media (min-width: 750px) {
        .Box-title, .u-h3, h3 {
            font-size: 1.83712em;
        }
    }

    @media (min-width: 750px) {
        .Col--small--10 {
            width: 83.3333%;
        }
    }

    @media (min-width: 750px) {
        .Box-title, .u-h3, h3 {
            font-size: 1.83712em;
        }
    }

    @media (min-width: 750px) {
        .Col--small--10 {
            width: 83.3333%;
        }
    }

    @media (min-width: 750px) {
        .Box-title, .u-h3, h3 {
            font-size: 1.83712em;
        }
    }

    @media (min-width: 750px) {
        .u-h2, h2 {
            font-size: 2.25em;
        }
    }


    @media (min-width: 750px) {
        .Col--small--10 {
            width: 83.3333%;
        }
    }

    @media (min-width: 750px) {
        .Box-title, .u-h3, h3 {
            font-size: 1.83712em;
        }
    }


    @media (min-width: 750px) {
        .Col--small--10 {
            width: 83.3333%;
        }
    }

    @media (min-width: 750px) {
        .Box-title, .u-h3, h3 {
            font-size: 1.83712em;
        }
    }

    .brd {
        border: 1px solid #e2eded;
        border-color: #eaf1f1 #e4eded #dbe7e7 #e4eded;
    }

    #app-cover {
        height: 42px;
    }

    #select-box {
        margin: 23px;
    }

    #select-button {
        position: relative;
        height: 45px;
        padding: 12px 14px;
        background-color: #A09174;
        cursor: pointer;
        color: #fff;
    }

    #options-view-button {
        position: absolute;
        right: 0;
        left: 0;
        width: 100%;
        height: 38px;
        margin: 0;
        opacity: 0;
        cursor: pointer;
        z-index: 3;
    }

    #selected-value {
        /*font-size: 16px;*/
        line-height: 1.35;
        margin-right: 26px;
        font-family: inherit;
        font-style: normal;
        text-transform: uppercase;
        letter-spacing: 4px;
        font-weight: 700;
        font-size: 1rem;
    }

    .option i {
        width: 16px;
        height: 16px;
    }

    .option, .label {
        color: #2d3667;
        font-size: 16px;
    }

    #chevrons {
        position: absolute;
        top: 7px;
        right: 10px;
        bottom: 0;
        width: 12px;
        padding: 9px 14px;
        color: #fff;
    }

    #chevrons i {
        display: block;
        height: 50%;
        font-size: 12px;
        text-align: right;
        color: #fff;
    }

    #options-view-button:checked + #select-button #chevrons i {
        color: #fff;
    }

    #options {
        top: 42px;
        right: 0;
        left: 0;
        width: 100%;
        margin: 0 auto;
        /*background-color: #fff;*/
        border-radius: 4px;
    }

    .option {
        position: relative;
        line-height: 1;
        transition: 0.3s ease all;
        z-index: 2;
        /*background: #fff;*/
        cursor: pointer;
    }

    .label {
        transition: .3s;
        display: block;
        padding: 0 14px;
        height: 0;
        opacity: 0;
    }

    #options-view-button:checked ~ #options .label {
        transition: .3s;
        display: block;
        padding: 10px 14px;
        height: 40px;
        opacity: 1;
        color: #fff
    }

    input[type="radio"] {
        position: absolute;
        right: 0;
        left: 0;
        width: 100%;
        height: 50%;
        margin: 0;
        opacity: 0;
        cursor: pointer;
    }

    .label {
        transition: 0.3s ease all;
    }

    .option input[type="radio"]:checked ~ .label:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: -1;
    }

    #option-bg {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        height: 40px;
        transition: 0.3s ease all;
        z-index: 1;
        display: none;
    }

    span.label {
        background: #1D1D1B;
        border-bottom: 1px solid rgba(255, 255, 255, .15);
    }

    .option:hover .label {
        color: #fff;
        background: #A09174
    }
</style>
